import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QFooter = () => {
  return (
    <footer className="footer">
      <div className="pt-3 pb-1 col text-center bg-warning footer-style">
        <p>
          <b>
            © {new Date().getFullYear()}, Quest Jobs. All rights reserved.{" "}
            <a
              href="https://fragoso09.github.io/questjobs2.github.io/"
              target="_blank"
              className="text-primary"
              rel="noopener noreferrer"
              style={{
                marginLeft: "20px",
                paddingRight: "10px",
              }}
            >
              Términos y Condiciones
            </a>
            <a
              href="https://fragoso09.github.io/questjobs2.github.io/avisoPrivacidad.html"
              target="_blank"
              className="text-primary"
              rel="noopener noreferrer"
              style={{
                marginLeft: "10px",
                paddingRight: "10px",
                marginRight: "10px",
              }}
            >
              Aviso de Privacidad
            </a>
            Información: info@questjobs2.com
          </b>
          <span>
            <a
              href="https://www.facebook.com/questjobs2"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#fff" }}
            >
              <FontAwesomeIcon
                className="social-network-mini"
                icon={["fab", "facebook"]}
                size="lg"
              />
            </a>
          </span>
          <span>
            <a
              href="https://www.instagram.com/questjobs2/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#fff" }}
            >
              <FontAwesomeIcon
                className="social-network-mini"
                icon={["fab", "instagram"]}
                size="lg"
              />
            </a>
          </span>
          <span>
            <a
              href="https://www.linkedin.com/company/questjobs2"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#fff" }}
            >
              <FontAwesomeIcon
                className="social-network-mini"
                icon={["fab", "linkedin"]}
                size="lg"
              />
            </a>
          </span>
          <span>
            <a
              href="https://twitter.com/questjobs2"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#fff" }}
            >
              <FontAwesomeIcon
                className="social-network-mini"
                icon={["fab", "twitter"]}
                size="lg"
              />
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
};

export default QFooter;
