import React, { Component, lazy, Suspense } from "react";
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
/*import { getToken } from "./firebase";
import Index from "./components/main/index";
import Login from "./components/main/login";
import Logout from "./components/main/logout";
import PerfilEmpleado from "./components/main/perfilEmpleado";
import PerfilEmpresa from "./components/main/perfilEmpresa";
import Busqueda from "./components/main/busqueda";
import Candidatos from "./components/main/candidatos";
import BusquedaPerfil from "./components/main/busquedaPerfil";
import VerifyEmail from "./components/main/verifyEmail";
import NewPassword from "./components/main/newPassword";*/
import ProtectedRoute from "./components/tools/protectedRoute";
import auth from "./services/authService";

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;
    const Index = lazy(() => import("./components/main/index"));
    const Login = lazy(() => import("./components/main/login"));
    const Logout = lazy(() => import("./components/main/logout"));
    const PerfilEmpleado = lazy(() =>
      import("./components/main/perfilEmpleado")
    );
    const PerfilEmpresa = lazy(() => import("./components/main/perfilEmpresa"));
    const Busqueda = lazy(() => import("./components/main/busqueda"));
    const Candidatos = lazy(() => import("./components/main/candidatos"));
    const BusquedaPerfil = lazy(() =>
      import("./components/main/busquedaPerfil")
    );
    const VerifyEmail = lazy(() => import("./components/main/verifyEmail"));
    const NewPassword = lazy(() => import("./components/main/newPassword"));
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={<div>Page is Loading...</div>}>
            <Switch>
              <ProtectedRoute
                path="/empresa"
                component={PerfilEmpresa}
                user={user}
              />
              <ProtectedRoute
                path="/empleado"
                component={PerfilEmpleado}
                user={user}
              />
              <ProtectedRoute
                path="/busqueda/:id"
                component={BusquedaPerfil}
                user={user}
              />
              <ProtectedRoute
                path="/busqueda"
                component={Busqueda}
                user={user}
              />
              <ProtectedRoute
                path="/candidatos"
                component={Candidatos}
                user={user}
              />
              <Route path="/verification/:tk" component={VerifyEmail} />
              <Route path="/recoverpass/:tk" component={NewPassword} />
              <Route path="/login/:register" component={Login} />
              <Route path="/login" component={Login} />
              <Route
                path="/index"
                render={(props) => <Index {...props} user={user} />}
              />
              <Route path="/logout" component={Logout} />
              <Route path="/lalobri" component={Index} />
              <Route path="/yorch" component={Index} />
              <Route path="/pato" component={Index} />
              <Route path="/mundogodinez" component={Index} />
              <Redirect to="/index" />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
