import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import App from "./App";
import QFooter from "./components/tools/qFooter";

//Import Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "rc-slider/assets/index.css";
import "./assets/css/main.css";
import "./assets/css/ppkit.css";
// import your fontawesome library
import "./fontawesome";
// import tippy library
import "react-tippy/dist/tippy.css";

//GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM,
};

TagManager.initialize(tagManagerArgs);
//GTM

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register(
      process.env.REACT_APP_PUBLIC_URL + "pwabuilder-sw.js"
    );
  });
}

ReactDOM.render(
  <React.Fragment>
    <div className="page-container">
      <div className="content-wrap">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </div>
      <QFooter />
    </div>
  </React.Fragment>,
  document.getElementById("root")
);
